import {PromoConfig} from "../../_core/types/theme/Promo.type";

const promo: PromoConfig = {
  tarot: {
    seo: {
      title: "Reveal your future",
      description: ""
    }
  }

};

// @ts-ignore
export default promo;
