import { HomeConfig } from '../../_core/types/theme/Home.type';

const home: HomeConfig = {
  header: {
    title: 'Best Online Psychics',
    subtitle: 'Consult the most accurate* psychics, fortune-tellers and spiritual advisors online.',
    ctaText: 'Find your Advisor Now',
    description: '*99.5% accuracy from @ client reviews.',
  },
  topAdvisors: {
    title: 'Top Advisors Today On Site',
    descriptionOne: 'Seeer\'s top online psychics have successfully completed over',
    descriptionTwo: 'readings and are a go-to source for clarity and foresight.',
    ctaText: 'Show All Top Advisors',
  },
  categories: {
    title: 'Top Reading Categories',
  },
  newAdvisors: {
    title: 'Gaining Popularity',
    description: 'Seeer\'s top online psychics have successfully completed over 1M+ readings and are a go-to source for clarity and foresight.',
    ctaText: 'See All Advisors',
  },
  promo: {
    title: '3 FREE min',
    description: 'with each of your first 3 advisors, then <b>50% OFF</b>.',
    ctaText: 'Try Now',
  },
  reasons: {
    title: 'Why Choose Us',
    reasons: [
      {
        colorScheme: 'feature',
        icon: 'eye',
        title: '1,128,366+',
        description: 'Total Readings Completed by Adviors',
      },
      {
        colorScheme: 'success',
        icon: 'dollar-sign',
        title: '100% Money Back',
        description: 'You’ll get a full refund, if you\'re not happy with your first reading.',
      },
      {
        colorScheme: 'warning',
        icon: 'star',
        title: '542,834+',
        description: 'Total reviews left by customers on Seeer.',
      },
    ],
  },
  testimonials: {
    title: 'People Love Our Advisors',
    mainTestimonial: 'As always the real deal, with no sugarcoating and dead on. I highly recommend chatting with her, you will not be disappointed.',
    testimonials: [
      {
        name: 'Iva',
        text: 'What a wonderful experience. Completely on point and I look coward to how he described what is on the horizon.',
        date: '22 Jan 2024',
      },
      {
        name: 'Michelle',
        text: 'What a wonderful experience. Completely on point and I look coward to how he described what is on the horizon.',
        date: '22 Jan 2024',
      },
      {
        name: 'Alex',
        text: 'A very kind and compassionate reader. She provides a lot of clarity and insights to situation and share with you her advices. It is really nice chatting with her:)',
        date: '22 Jan 2024',
      },
    ],
  },
  howToStart: {
    title: 'How do I start?',
    steps: [
      {
        title: 'Create account',
        description: 'Sign up with Google, Facebook, or your email',
      },
      {
        title: 'Choose duration',
        description: 'Select a number of minutes you want to chat with advisor.',
      },
      {
        title: 'Choose payment method',
        description: 'Pay with your credit card or via PayPal.',
      },
      {
        title: 'Start chatting!',
        description: 'Start talking to the advisor and see your future!',
      },
    ],
  },
  about: {
    title: 'About Seeer',
    content: [
      'Explore our highest-rated psychic and medium services to navigate towards happiness and fulfillment in your life.',
      'From career guidance, financial predictions, pet communication, or getting a glimpse into your future, Seeer’s advisors are here to help you.',
      'Want to know more about death and crossover, or dive deep into your love life? Seeer’s secure online psychic chats will align your life with the best insights and guidance!',
    ],
    ctaText: 'Learn more',
    image: '/static/images/home/about.png',
  },
  seo: {
    title: 'Online Psychic Chat, Live Psychic Readings at Seeer',
    description: 'Discover happiness and fulfillment with Seeer\'s top online psychics. Gain insights and life guidance from the most accurate psychic advisors at Seeer.com!',
  },
};

// @ts-ignore
export default home;
